<template>
  <svg class="testimonial-header-svg" width="130" height="130" viewBox="0 0 130 130">
    <g id="Group_1280" data-name="Group 1280" transform="translate(-593 -28)">
      <path id="Path_2048" data-name="Path 2048"
        d="M14.333,27a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,27Z" transform="translate(582 27.667)"
        fill="#6c63ff" />
      <path id="Path_2049" data-name="Path 2049"
        d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z"
        transform="translate(625.333 17.667)" />
      <path id="Path_2051" data-name="Path 2051"
        d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z" transform="translate(627.333 17)" />
      <path id="Path_2052" data-name="Path 2052"
        d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z" transform="translate(631.333 24.333)"
        fill="#6c63ff" />
      <path id="Path_2053" data-name="Path 2053"
        d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
        transform="translate(582 49)" />
      <path id="Path_2054" data-name="Path 2054"
        d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z" transform="translate(590 66.333)"
        fill="#6c63ff" />
      <path id="Path_2055" data-name="Path 2055"
        d="M20.167,63a4.167,4.167,0,1,0,4.167,4.167A4.167,4.167,0,0,0,20.167,63Zm4.167,25A1.667,1.667,0,1,0,26,89.667,1.667,1.667,0,0,0,24.333,88Zm98.333-55a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,122.667,33Z"
        transform="translate(585.333 31.667)" fill="#fff" />
      <g id="Group_1279" data-name="Group 1279">
        <path id="Path_2050" data-name="Path 2050"
          d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z"
          transform="translate(583 18)" />
        <g id="Group_1278" data-name="Group 1278" transform="translate(621 61)">
          <path id="Path_2373" data-name="Path 2373"
            d="M20.383,29.018l-1.645-3.427-1.721,3.427a.58.58,0,0,1-.436.326l-3.687.546,2.635,2.746a.6.6,0,0,1,.167.523l-.619,3.77,3.355-1.74a.565.565,0,0,1,.539,0l3.279,1.777-.567-3.805a.6.6,0,0,1,.167-.526l2.673-2.665h0a.045.045,0,0,0,.012-.052l-3.714-.572A.58.58,0,0,1,20.383,29.018Z"
            transform="translate(-0.303 -3.753)" />
          <path id="Path_2374" data-name="Path 2374"
            d="M44.94,25.854l-6.055-.9a.58.58,0,0,1-.436-.326L35.746,19a.434.434,0,0,0-.4-.248.439.439,0,0,0-.4.257l-2.7,5.621a.58.58,0,0,1-.436.326l-6.057.9a.432.432,0,0,0-.349.306.478.478,0,0,0,.106.481l4.365,4.375a.6.6,0,0,1,.166.523L29.03,37.732a.434.434,0,0,0,.623.461l5.427-2.92a.565.565,0,0,1,.539,0l5.386,2.92a.435.435,0,0,0,.47-.041.446.446,0,0,0,.174-.44l-1.037-6.172a.6.6,0,0,1,.167-.526l4.39-4.38h0a.469.469,0,0,0,.116-.476A.424.424,0,0,0,44.94,25.854Z"
            transform="translate(1.649 -5.028)" />
          <path id="Path_2375" data-name="Path 2375"
            d="M52.557,29.018l-1.646-3.427-1.721,3.427a.58.58,0,0,1-.436.326l-3.687.546L47.7,32.635a.6.6,0,0,1,.167.523l-.619,3.77,3.355-1.74a.565.565,0,0,1,.539,0l3.279,1.777-.567-3.805a.6.6,0,0,1,.167-.526l2.673-2.665h0a.045.045,0,0,0,.012-.052l-3.714-.572A.58.58,0,0,1,52.557,29.018Z"
            transform="translate(4.725 -3.753)" />
          <path id="Path_2376" data-name="Path 2376"
            d="M72.642,7.185H5.37a3.405,3.405,0,0,0-3.365,3.453v44.2A3.413,3.413,0,0,0,5.37,58.286h6.51V70.222a.6.6,0,0,0,.324.534.617.617,0,0,0,.254.059.58.58,0,0,0,.358-.13l14.87-12.4H72.642A3.411,3.411,0,0,0,76,54.833v-44.2A3.4,3.4,0,0,0,72.642,7.185ZM27.028,34.252,24.574,36.7l.579,3.45a1.237,1.237,0,0,1-.472,1.2,1.179,1.179,0,0,1-1.265.1L20.4,39.815l-3.033,1.631a1.151,1.151,0,0,1-.552.141,1.174,1.174,0,0,1-.7-.238,1.223,1.223,0,0,1-.473-1.191l.568-3.461L13.763,34.25a1.258,1.258,0,0,1-.295-1.272,1.2,1.2,0,0,1,.961-.832l3.387-.5L19.327,28.5a1.2,1.2,0,0,1,1.075-.684h0a1.2,1.2,0,0,1,1.07.674l1.515,3.156,3.385.5a1.2,1.2,0,0,1,.963.838A1.25,1.25,0,0,1,27.028,34.252Zm16.2-16.108,2.109-2.9a.567.567,0,0,1,.809-.12.6.6,0,0,1,.118.83l-2.109,2.9a.568.568,0,0,1-.809.12A.6.6,0,0,1,43.229,18.144Zm-4.806-3.967a.578.578,0,1,1,1.156,0v4.132a.578.578,0,1,1-1.156,0Zm-6.577.571a.569.569,0,0,1,.811.105l2.481,3.281a.6.6,0,0,1-.1.833.569.569,0,0,1-.811-.105l-2.481-3.281A.6.6,0,0,1,31.846,14.748ZM49.63,29.642,45.458,33.8l.985,5.865a1.65,1.65,0,0,1-.629,1.6,1.571,1.571,0,0,1-1.689.136L39,38.627,33.846,41.4a1.53,1.53,0,0,1-.734.188,1.564,1.564,0,0,1-.938-.316,1.625,1.625,0,0,1-.63-1.583L32.51,33.8l-4.154-4.163a1.681,1.681,0,0,1-.391-1.7,1.6,1.6,0,0,1,1.28-1.108L35,25.985l2.568-5.35a1.583,1.583,0,0,1,2.862-.009l2.573,5.359,5.755.852a1.591,1.591,0,0,1,1.281,1.115A1.669,1.669,0,0,1,49.63,29.642Zm14.6,4.609L61.776,36.7l.579,3.45a1.237,1.237,0,0,1-.472,1.2,1.179,1.179,0,0,1-1.265.1L57.6,39.815l-3.033,1.631a1.151,1.151,0,0,1-.552.141,1.174,1.174,0,0,1-.7-.238,1.223,1.223,0,0,1-.473-1.191l.568-3.461L50.964,34.25a1.258,1.258,0,0,1-.295-1.272,1.2,1.2,0,0,1,.961-.832l3.387-.5,1.51-3.147a1.2,1.2,0,0,1,1.076-.684h0a1.2,1.2,0,0,1,1.07.674l1.515,3.156,3.385.5a1.2,1.2,0,0,1,.963.838A1.253,1.253,0,0,1,64.23,34.252Z"
            transform="translate(-2.005 -7.185)" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TestimonialHeaderIcon"
}
</script>

<style lang="scss" scoped>
:root {
  .testimonial-header-svg {

    #Path_2376,
    #Path_2375,
    #Path_2374,
    #Path_2373 {
      fill: #ef7974;
    }

    #Path_2053,
    #Path_2051,
    #Path_2049 {
      fill: #ec615b;
    }

    #Path_2050 {
      fill: #6c63ff;
      opacity: 0.147;
    }
  }
}

:root.dark-theme {
  .testimonial-header-svg {

    #Path_2376,
    #Path_2375,
    #Path_2374,
    #Path_2373 {
      fill: #F8BF7B;
    }

    #Path_2053,
    #Path_2051,
    #Path_2049 {
      fill: #F8BF7B;
    }

    #Path_2050 {
      fill: #1e2129;
      opacity: 1;
    }
  }
}
</style>