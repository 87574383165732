<template>
  <div class="testimonials-component">
    <div class="component-nav-icon">
      <TestimonialHeaderIcon />
      <div class="c-n-title">
        <span>TESTIMONIALS</span>
        <span>&nbsp;</span>
      </div>
    </div>

    <div class="t-c-content">
      <v-container class="elevation-2 t-c-c-container">
        <v-card elevation="2" max-width="800" class="mx-auto">
          <svg class="t-c-c-quotes-left" id="qnew" xmlns="http://www.w3.org/2000/svg" width="50" height="48.237"
            viewBox="0 0 50 48.237">
            <path id="Path_207" data-name="Path 207"
              d="M13.562,0h7.365V48.237H0C0,30.9,5.669,11.866,13.562,0M29.074,48.237H50V0H42.635C34.743,11.866,29.074,30.9,29.074,48.237"
              transform="translate(0 0)" fill-rule="evenodd" />
          </svg>

          <svg class="t-c-c-quotes-right" id="qnew" xmlns="http://www.w3.org/2000/svg" width="50" height="48.237"
            viewBox="0 0 50 48.237">
            <path id="Path_2060" data-name="Path 2060"
              d="M13.562,0h7.365V48.237H0C0,30.9,5.669,11.866,13.562,0M29.074,48.237H50V0H42.635C34.743,11.866,29.074,30.9,29.074,48.237"
              transform="translate(50 48.237) rotate(180)" fill-rule="evenodd" />
          </svg>

          <v-carousel class="t-c-c-carousel" :continuous="true" :cycle="cycle" show-arrows-on-hover height="400">
            <template v-slot:prev="{ on, attrs }">
              <svg v-bind="attrs" v-on="on" xmlns="http://www.w3.org/2000/svg" width="13" height="34.302"
                viewBox="0 0 18 34.302">
                <path id="chevron-right"
                  d="M12.787,17.151.542,3.539A2.075,2.075,0,0,1,.786.516,2.328,2.328,0,0,1,3.958.749l13.5,15.007a2.068,2.068,0,0,1,0,2.79L3.958,33.553a2.328,2.328,0,0,1-3.173.233,2.074,2.074,0,0,1-.244-3.023Z"
                  transform="translate(18 34.302) rotate(180)" />
              </svg>
            </template>
            <template v-slot:next="{ on, attrs }">
              <svg v-bind="attrs" v-on="on" xmlns="http://www.w3.org/2000/svg" width="13" height="34.302"
                viewBox="0 0 18 34.302">
                <path id="chevron-right"
                  d="M22.787,25.151,10.542,11.539a2.075,2.075,0,0,1,.244-3.023,2.328,2.328,0,0,1,3.173.233l13.5,15.007a2.068,2.068,0,0,1,0,2.79l-13.5,15.007a2.328,2.328,0,0,1-3.173.233,2.074,2.074,0,0,1-.244-3.023Z"
                  transform="translate(-10 -8)" />
              </svg>
            </template>
            <v-carousel-item>
              <v-sheet class="d-flex" height="100%" tile>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-col cols="12" sm="4" md="3" lg="3" class="t-c-c-c-left">
                    <!-- <img width="130" height="130" :src="saiDileepImg" alt srcset /> -->
                    <testimonial-male></testimonial-male>
                    <span>Sai Dileep</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="t-c-c-c-right">
                    <span class="work-relation">(Worked with Sai in the same group)</span>
                    <p class="mt-2">I worked with Naveen for more than a couple of years and he was, quite frankly, a
                      great partner. He was able to very quickly turn design into reality and was always thinking out of
                      the box.</p>
                    <span>&nbsp;</span>
                    <p>There were several times when achieving success was a challenge due to shortages in personnel,
                      equipment, or time. Being creative, Naveen excelled during these times and did so with great
                      spirit and enthusiasm. His technical depth, ability to lead team and pick latest cutting edge
                      technologies has been instrumental for the success.</p>
                    <span>&nbsp;</span>
                    <p>I truly enjoyed working with him and strongly recommend Naveen for building enterprise-level
                      products/platforms.</p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet class="d-flex" height="100%" tile>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-col cols="12" sm="4" md="3" lg="3" class="t-c-c-c-left">
                    <!-- <img width="130" height="130" :src="darshanImg" alt srcset /> -->
                    <testimonial-male></testimonial-male>
                    <span>Darshan Yadav L</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="t-c-c-c-right">
                    <span class="work-relation">(Were students together)</span>
                    <p class="mt-2">Naveen D B is a great learner. He has a deep understanding of front end technologies
                      and implemented them in complex projects. He also takes full responsibility for the work assigned
                      and completes them within the timeline provided. I could highly recommend Naveen since he can pull
                      off any intricate work with minimal risk at stake.</p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet class="d-flex" height="100%" tile>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-col cols="12" sm="4" md="3" lg="3" class="t-c-c-c-left">
                    <!-- <img width="130" height="130" :src="zalakImg" alt srcset /> -->
                    <testimonial-female></testimonial-female>
                    <span>Zalak Upadhyay</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="t-c-c-c-right">
                    <span class="work-relation">(Worked with Zalak in the same group)</span>
                    <p class="mt-2">Naveen is an amazing self-driven professional, with great skills in UI development.
                      His expertise in the field and can-do attitude makes him an indispensable part of the team and
                      ensures smooth flow of work throughout the day.</p>
                    <span>&nbsp;</span>
                    <p>I had the pleasure of working with him on various interesting projects at Kogentix and
                      Accenture(post acquisition). Naveen was always up for a challenge and that made all the
                      difference.</p>
                    <span>&nbsp;</span>
                    <p>His skills and attitude would be a great value add to any institution who may want to hire him.
                    </p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet class="d-flex" height="100%" tile>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-col cols="12" sm="4" md="3" lg="3" class="t-c-c-c-left">
                    <!-- <img width="130" height="130" :src="jojoImg" alt srcset /> -->
                    <testimonial-male></testimonial-male>
                    <span>Jojo John Moolayil</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="t-c-c-c-right">
                    <span class="work-relation">(Managed Naveen directly)</span>
                    <p class="mt-2">Naveen is an extremely diligent and goal oriented individual. His passion for
                      continuously learning and improving his skillsets and problem solving abilities makes him an
                      extremely dependable team member. Naveen is technically sound and has a rich exposure to a variety
                      of technologies in UI development.</p>
                    <span>&nbsp;</span>
                    <p>I worked with Naveen for a major Dutch Lighting solutions provider with 2 other team members on a
                      custom large scale web solution for Industrial IoT where his contributions played a key role in
                      the success of the project.</p>
                    <span>&nbsp;</span>
                    <p>I wish Naveen humongous success for all his future endeavors and hope to see him rise as a leader
                      in the Enterprise world.</p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet class="d-flex" height="100%" tile>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-col cols="12" sm="4" md="3" lg="3" class="t-c-c-c-left">
                    <!-- <img width="130" height="130" :src="darsithImg" alt srcset /> -->
                    <testimonial-male></testimonial-male>
                    <span>Darshith Badiyani</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="t-c-c-c-right">
                    <span class="work-relation">(Worked with Darshith in the same group)</span>
                    <p class="mt-2">Naveen is one of the serious and hardworking person I have worked with at Flutura.
                      He has great experience on the UI front and is open to learn new things and pitch his own ideas
                      for best user experience. He's a responsible developer willing to go the distance for best output.
                    </p>
                    <span>&nbsp;</span>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-card>
        <svg class="t-c-c-bg-leaf1" width="200" height="80" viewBox="0 0 225 105">
          <g id="Group_1264" data-name="Group 1264" transform="translate(862.102 -5156)">
            <path id="Path_2061" data-name="Path 2061"
              d="M791.474,283.18c-.5.378-.993.74-1.5,1.1H664.643q-1.4-.535-2.788-1.1A149.8,149.8,0,0,1,625.3,262.054,145.072,145.072,0,0,1,606.4,244.81c-6.8-7.761-13.062-16.218-15.975-25.854s-2.038-20.682,4.455-28.673a27,27,0,0,1,7.3-6.2c.467-.274.939-.537,1.424-.784a38.861,38.861,0,0,1,26.379-2.945c12.554,3.011,22.987,11.419,30.431,21.28s12.277,21.176,17.038,32.37c4.833-19.98,19.467-37.877,39.234-46.976s44.315-8.989,63.657.867a63.5,63.5,0,0,1,16.276,11.9c.467.466.922.943,1.371,1.426,9.435,10.141,15.337,23.129,15.821,36.522C814.426,255,805.812,272.326,791.474,283.18Z"
              transform="translate(-1450.939 4976.723)" />
            <path id="Path_2062" data-name="Path 2062"
              d="M678.882,287.439h-1.772c-.023-.367-.053-.729-.089-1.1a114.947,114.947,0,0,0-3.876-21.247,127.142,127.142,0,0,0-22.633-44.129,131.8,131.8,0,0,0-38.7-33.16.78.78,0,0,1-.413-.565.8.8,0,0,1,.361-.812.941.941,0,0,1,.945-.044,1.349,1.349,0,0,1,.118.071,133.035,133.035,0,0,1,54.252,57.725,120.843,120.843,0,0,1,11.71,42.16C678.823,286.709,678.852,287.077,678.882,287.439Z"
              transform="translate(-1460.167 4973.562)" fill="#fff" />
            <path id="Path_2063" data-name="Path 2063"
              d="M854.842,217.974a.115.115,0,0,1-.041.011c-1.294.219-2.594.45-3.882.7A185.562,185.562,0,0,0,820,227.4a188,188,0,0,0-28.653,13.711,184.677,184.677,0,0,0-25.669,18.143,174.2,174.2,0,0,0-21.853,22,162.756,162.756,0,0,0-13.358,18.681c-.224.362-.443.729-.662,1.1h-2.026c.213-.367.431-.735.656-1.1a164.413,164.413,0,0,1,16.53-22.668,176.945,176.945,0,0,1,22.633-21.8,187.921,187.921,0,0,1,26.219-17.7,190.569,190.569,0,0,1,29.256-13.251,186.144,186.144,0,0,1,30.4-7.964l.863-.148C855.439,216.213,855.911,217.76,854.842,217.974Z"
              transform="translate(-1507.796 4959.967)" fill="#fff" />
            <path id="Path_2082" data-name="Path 2082"
              d="M820.971,322.837a126.578,126.578,0,0,0-72.087,12.124,116.465,116.465,0,0,0-17.445,10.7c-.891.661.37,1.818,1.253,1.163a117.192,117.192,0,0,1,31.777-16.583,125.915,125.915,0,0,1,35.485-6.683,121.516,121.516,0,0,1,20.546.865.929.929,0,0,0,1.09-.575.822.822,0,0,0-.619-1.012Z"
              transform="translate(-1509.168 4912.357)" fill="#fff" />
          </g>
        </svg>
        <svg class="t-c-c-bg-leaf2" width="40" height="50" viewBox="0 0 49.731 70.057">
          <g id="Group_1269" data-name="Group 1269" transform="translate(1217.125 -5771.596)">
            <path id="Path_932" data-name="Path 932"
              d="M350.427,621.962c0-13.512,11.132-45.575,24.865-45.575s24.866,32.061,24.866,45.575a24.869,24.869,0,0,1-49.731,0Z"
              transform="translate(-1567.552 5195.209)" />
            <path id="Path_933" data-name="Path 933"
              d="M381.549,652c-.113,0-.2-.053-.2-.118V602.407c0-.065.092-.118.2-.118s.2.053.2.118v49.479c0,.065-.091.118-.2.118Z"
              transform="translate(-1573.809 5189.649)" fill="#fff" />
            <path id="Path_934" data-name="Path 934"
              d="M379.052,620.524a.206.206,0,0,1-.144-.059l-9.842-9.683a.2.2,0,0,1,0-.285.207.207,0,0,1,.289,0h0l9.842,9.683a.2.2,0,0,1,.044.219.2.2,0,0,1-.189.124Z"
              transform="translate(-1571.312 5187.899)" fill="#fff" />
            <path id="Path_935" data-name="Path 935"
              d="M381.549,640.085a.2.2,0,0,1-.189-.124.2.2,0,0,1,.044-.219l16.482-16.217a.207.207,0,0,1,.289,0,.2.2,0,0,1,0,.285l-16.483,16.217A.205.205,0,0,1,381.549,640.085Z"
              transform="translate(-1573.809 5185.103)" fill="#fff" />
          </g>
        </svg>
        <svg class="t-c-c-bg-leaf3" width="150" height="80" viewBox="0 0 165 105">
          <g id="Group_1265" data-name="Group 1265" transform="translate(637.319 -5704.745)">
            <path id="Path_2083" data-name="Path 2083"
              d="M826.972,639.116c-8.342-5.734-13.409-14.792-12.08-23.609s9.559-16.924,20.234-18.8,22.845,3.311,26.088,11.794c1.784-16.355,3.841-33.4,14.53-47.323,9.679-12.611,26.443-21.635,44.612-23.25s37.3,4.483,48.629,16.148,14.105,28.624,6.169,42.017c-5.846,9.866-16.617,17.208-27.9,23.182-36.466,19.139-80.2,26.923-123.162,21.918Z"
              transform="translate(-1452 5167.069)" />
            <path id="Path_2084" data-name="Path 2084"
              d="M967.412,545.794a274.848,274.848,0,0,0-41.06,33.167A236.558,236.558,0,0,0,880.1,641.827c-.833,1.66,2.481,2.383,3.3.741,18.613-36.981,48.328-69.576,86.426-94.8C971.546,546.632,969.109,544.67,967.412,545.794Z"
              transform="translate(-1468.393 5164.028)" fill="#fff" />
          </g>
        </svg>
      </v-container>
    </div>
  </div>
</template>

<script>
import TestimonialHeaderIcon from "../SVG/TestimonialHeaderIcon";
import TestimonialMale from "../SVG/TestimonialMale.vue";
import TestimonialFemale from "../SVG/TestimonialFemale.vue";

export default {
  name: "Testimonials",
  components: {
    TestimonialHeaderIcon,
    TestimonialMale,
    TestimonialFemale
  },
  data() {
    return {
      cycle: false,
      darshanImg: "https://media-exp1.licdn.com/dms/image/C5603AQGJ1y4HBzsVEg/profile-displayphoto-shrink_100_100/0/1602987497077?e=1643241600&v=beta&t=EJkIL1akf9z31_yfjxWW65WoLKhQL4lrl59bDANby2s",
      saiDileepImg: "https://media-exp1.licdn.com/dms/image/C4D03AQHuDWK0KigZNw/profile-displayphoto-shrink_100_100/0/1605262603093?e=1643241600&v=beta&t=ZgvibgD-KzAnv1cLJ-T1FSxtiLPhEJQFQvR5ao7TQKs",
      zalakImg: "https://media-exp1.licdn.com/dms/image/C5603AQFB1Eh2mXda3g/profile-displayphoto-shrink_100_100/0/1516334723327?e=1643241600&v=beta&t=K6Ej049bbueUwaGFi5Fq1WNpwbJDGvOJu5jHAMFmcvQ",
      jojoImg: "https://media-exp1.licdn.com/dms/image/C5603AQFsIMMTuBompQ/profile-displayphoto-shrink_100_100/0/1588191720129?e=1643241600&v=beta&t=eRR1FbP0n6bpZRAkAm-QH-5I0KRxf0lZQuPy2FwLvc0",
      darsithImg: "https://media-exp1.licdn.com/dms/image/C5103AQHjwEuWRsQdTQ/profile-displayphoto-shrink_100_100/0/1538061975538?e=1643241600&v=beta&t=aGF4F6RYNVGN18-74rGzBUD9rhxN66nr5FjUhPMIkDA"
    }
  }
}
</script>
