import { render, staticRenderFns } from "./TestimonialHeaderIcon.vue?vue&type=template&id=703f641e&scoped=true&"
import script from "./TestimonialHeaderIcon.vue?vue&type=script&lang=js&"
export * from "./TestimonialHeaderIcon.vue?vue&type=script&lang=js&"
import style0 from "./TestimonialHeaderIcon.vue?vue&type=style&index=0&id=703f641e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703f641e",
  null
  
)

export default component.exports